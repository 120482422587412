'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:teamGeneral
 * @description teamGeneral Shows the team details.
 */
angular.module('uasApp').component('teamGeneral', {
  templateUrl: 'es6/team/team.general.html',
  bindings: {
    team: '<',
    operations: '<'
  },
  controllerAs: 'teamGeneralController',
  controller: function(Person) {
    const teamGeneralController = this;

    teamGeneralController.$onInit = function() {
      loadData();
    };

    function loadData() {
      Person.findAll(teamGeneralController.team.personIds).$promise.then((persons) => {
        teamGeneralController.persons = persons;
      });
    }
  }
});
