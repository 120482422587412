'use strict';

angular.module('uasApp').component('structureSelect', {
  templateUrl: 'es6/link/structure.select.html',
  bindings: {
    studyId: '<',
    selected: '<',
    isReadOnly: '<?',
    onToggle: '&'
  },
  controllerAs: 'structureSelectController',
  controller: function (EntityService, ModuleGroup, Nodes) {
    const structureSelectController = this;
    const LIMIT = 10;

    const manager = Nodes.manager({
      build,
      load
    });

    function build(node, parent) {
      const result = Nodes.build(node, parent);

      return _.extend(result, {
        selected: _.some(structureSelectController.selected, node.self),
        open: _.some(structureSelectController.openNodes, node.entity),
        limit: LIMIT
      });
    }

    function load(node) {
      return ModuleGroup.modules({
        id: node.id,
        active: true
      }).$promise.then((modules) => {
        node.modules = _.map(modules, (module) => {
          module.moduleId = _.result(module, 'module.id');
          module.moduleGroupId = node.id;
          module.visible = true;
          module.selected = _.some(structureSelectController.selected, (selected) => selected.id === module.moduleId && selected.type === 'module');
          return module;
        });
      });
    }

    structureSelectController.$onInit = function () {
      structureSelectController.limit = LIMIT;
      structureSelectController.groups = [];

      structureSelectController.setFiltered(false);
      structureSelectController.setSimple(true);
      structureSelectController.academicYearId = sessionStorage.academicYear;

      structureSelectController.ngModelCtrl.$render = function () {
        structureSelectController.selected = structureSelectController.ngModelCtrl.$modelValue || [];
      };
    };

    function loadData() {
      structureSelectController.loading = true;

      return manager.init({
        entity: {
          id: structureSelectController.studyId,
          type: 'study'
        },
        loadAll: true
      }).then((groups) => {
        structureSelectController.groups = groups;

        update();
      }).finally(() => {
        structureSelectController.loading = false;
      });
    }

    structureSelectController.$onChanges = function () {
      loadData();
    };

    structureSelectController.onExpand = function (expanded) {
      return manager.expand(structureSelectController.groups, expanded);
    };

    structureSelectController.setSimple = function (simple) {
      structureSelectController.simple = simple;
    };

    structureSelectController.setFiltered = function (filtered) {
      structureSelectController.filtered = filtered;
    };

    structureSelectController.filter = function (entity) {
      let result = true;
      if (structureSelectController.filtered) {
        result = _.get(entity, 'selected') || _.get(entity, 'childSelected');
      }
      if (structureSelectController.simple && !structureSelectController.filtered) {
        result = result && _.get(entity, 'self.type') === 'module-group';
      }
      return result;
    };

    structureSelectController.onSelect = function (group) {
      group.selected = group.selected !== true;
      manager.prepare(group).finally(update);

      const entity = EntityService.toReference(group);

      structureSelectController.onToggle({
        entity,
        selected: group.selected
      });
    };

    structureSelectController.onMore = function (group) {
      group.limit += LIMIT;
    };

    structureSelectController.onLess = function (group) {
      group.limit -= LIMIT;
    };

    structureSelectController.onGroup = function (group) {
      manager.toggle(group).finally(update);
    };

    structureSelectController.onModule = function (module) {
      module.selected = module.selected !== true;
      update();

      const entity = {
        id: module.module.id,
        type: 'module'
      };

      structureSelectController.onToggle({
        entity,
        selected: module.selected
      });
    };

    function update() {
      _.forEach(structureSelectController.groups, (group) => {
        group.childSelected = isChildSelected(group);
      });
    }

    function isChildSelected(group) {
      _.forEach(group.children, (child) => {
        child.childSelected = isChildSelected(child);
      });

      group.childSelected = _.some(group.modules, 'selected') ||
        _.some(group.children, 'selected') ||
        _.some(group.children, 'childSelected');

      return group.childSelected;
    }
  }
});
