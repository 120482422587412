'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:personList
 * @description Shows the person list.
 */
angular.module('uasApp')
  .component('personList', {
    bindings: {
        operations: '<',
        parameters: '<'
    },
    templateUrl: 'es6/person/person.list.html',
    controllerAs: 'personsController',
    controller: function($state, Pageable, Person, PersonModal, Message, AuthService, COLUMN_TYPES) {
        const personsController = this;

        personsController.$onInit = () => {
            personsController.headers = [
                { code: 'externalId', name: 'person_id' },
                { code: 'code', name: 'code' },
                { code: 'fullName', name: 'name' },
                { code: 'email', name: 'email' },
                { code: 'role', name: 'role' },
                { code: 'lastLoginDate', name: 'last_login', valueType: COLUMN_TYPES.DATE_TIME },
                { code: 'startDate', name: 'start_date', valueType: COLUMN_TYPES.DATE },
                { code: 'endDate', name: 'end_date', valueType: COLUMN_TYPES.DATE }
            ];

            personsController.open = personsController.parameters.open === 'true';
            personsController.pageable = Pageable.parse(personsController.parameters);
            parseParameters();

            personsController.search();
        };

        function parseParameters() {
            personsController.text = personsController.parameters.text;
            if (personsController.parameters.role) {
                personsController.role = personsController.parameters.role;
            }
            if (personsController.parameters.roleId) {
                personsController.roleId = parseInt(personsController.parameters.roleId);
            }
            if (personsController.parameters.active) {
                personsController.active = String(personsController.parameters.active);
            }
            if (personsController.parameters.external) {
                personsController.external = String(personsController.parameters.external);
            }
            if (personsController.parameters.ignore) {
                personsController.ignore = String(personsController.parameters.ignore);
            }
            if (personsController.parameters.passwordFilled) {
                personsController.passwordFilled = String(personsController.parameters.passwordFilled);
            }
        }

        personsController.onChange = function () {
            const parameters = _.extend(getParameters(), {
                open: personsController.open
            });

            $state.go('persons', parameters);
        };

        personsController.onToggle = function (open) {
            personsController.open = open === true;
            personsController.onChange();
        };

        personsController.search = () => {
            personsController.loading = true;

            const parameters = getParameters();
            return Person.page(parameters).$promise.then((persons) => {
                personsController.persons = persons;
            }).finally(() => {
                personsController.loading = false;
            });
        };

        function getParameters() {
            return personsController.pageable.build({
                academicYearId: sessionStorage.academicYear,
                text: personsController.text || '',
                role: personsController.role,
                roleId: personsController.roleId,
                active: personsController.active,
                external: personsController.external,
                ignore: personsController.ignore,
                passwordFilled: personsController.passwordFilled
            });
        }

        personsController.getRows = (page) => {
            const parameters = getParameters();
            const paged = _.extend(parameters, {
                size: 100,
                page: page
            });

            return Person.page(paged).$promise;
        };

        personsController.delete = function (person) {
            Person.remove(person).$promise.then(() => {
                personsController.search();
                Message.onRemoved();
            });
        };

        personsController.become = function(person, $event) {
            $event.preventDefault();
            $event.stopPropagation();

            AuthService.loginAs(person);
        };

        personsController.create = function($event) {
            personsController.edit({
                role: 'USER'
            }, $event);
        };

        personsController.edit = function(person, $event) {
            $event.preventDefault();
            $event.stopPropagation();

            PersonModal.edit({
                person: person,
                operations: personsController.operations,
                hidePasswordField: false
            });
        };
    }
});
