'use strict';

angular.module('uasApp').component('pageEdit', {
    templateUrl: 'es6/page/page.edit.html',
    bindings: {
        page: '<'
    },
    controllerAs: 'pageEditController',
    controller: function($filter, $q, $state, enumFilter, i18nFilter, Page, EntityService, EntityTypes, Enums, ItemType, CustomField, Workflow, Message, PAGES, Tab) {
        const pageEditController = this;
        const SEPARATOR = ',';

        pageEditController.$onInit = function () {
            pageEditController.tabs = [{
                title: 'Static.Page.Pages.Tab.General',
                content: 'general.html',
                visible: () => true
            }, {
                title: 'Static.Page.Pages.Tab.Fields',
                content: 'fields.html',
                visible: () => _.get(pageEditController.route, 'items') === true
            }, {
                title: 'Static.Page.Pages.Tab.Pages',
                content: 'pages.html',
                visible: () => _.get(pageEditController.route, 'composite') === true
            }];

            pageEditController.routes = _.sortBy(PAGES, 'name');

            setParameters();
            loadData();
        };

        pageEditController.onTypeChanged = function() {
            pageEditController.page.items = [];
            loadData();
        };

        pageEditController.onRoute = function() {
            setParameters();

            if (!pageEditController.route.items) {
                pageEditController.selectedItems = [];
            }

            if (!pageEditController.route.composite) {
                pageEditController.selectedChildren = [];
            }

            setUnusedChildren();
        };

        function setParameters() {
            const route = _.find(pageEditController.routes, { name: pageEditController.page.route });
            pageEditController.route = route;

            const parameters = _.get(route, 'parameters', []);
            pageEditController.parameters = angular.copy(parameters);

            _.forEach(pageEditController.parameters, (parameter) => {
                parameter.tab = parameter.tab === true;
                parameter.value = getParameterValue(parameter);
                parameter.label = getParameterLabel(parameter);
            });
        }

        function getParameterValue(parameter) {
            const found = _.find(pageEditController.page.parameters, { name: parameter.name });
            const defaultValue = _.get(parameter, 'defaultValue', '');
            const value = _.get(found, 'value', defaultValue);

            return parameter.isArray ? toArray(value) : value;
        }

        function toArray(value) {
            if (!value) {
                return [];
            } else if (_.isString(value)) {
                return _.split(value, SEPARATOR);
            }

            return value;
        }

        function getParameterLabel(parameter) {
            if (parameter.label) {
                return _.capitalize(parameter.label);
            }

            const words = parameter.name.match(/[A-Za-z][a-z]*/g) || [];
            return _.capitalize(words.join(' '));
        }

        function loadData() {
            pageEditController.loading = true;
            $q.all([
                getTypes(),
                getWorkflows(),
                getTabs(),
                Page.query().$promise.then((pages) => {
                    return _.map(pages, (page) => {
                        page.name = i18nFilter(page.titles) || page.name;
                        return page;
                    });
                }),
                Enums.constants('PageItemType').$promise
            ]).then(([types, workflows, tabs, pages, constants]) => {
                pageEditController.types = types;
                pageEditController.constants = constants;
                pageEditController.workflows = workflows;
                pageEditController.usedTabs = tabs;
                pageEditController.pages = pages;

                return setFields();
            }).finally(() => {
                pageEditController.loading = false;
            });
        }

        function getTypes() {
            return $q.all([
                EntityTypes.load(),
                ItemType.query().$promise
            ]).then(([entityTypes, itemTypes]) => {
                const filtered = _.filter(itemTypes, (itemType) => isSupported(itemType.context.rootType));
                return _.concat(entityTypes, filtered);
            });
        }

        function isSupported(entityType) {
            const rootType = getRootType();
            return !rootType || rootType === entityType;
        }

        function getWorkflows() {
            if (angular.isUndefined(pageEditController.page.id)) {
                return $q.resolve([]);
            }

            return Workflow.query({
                pageId: pageEditController.page.id
            }).$promise.then((workflows) => {
                return _.sortBy(workflows, ['type', 'sequence', 'id']);
            });
        }

        function getRootType() {
            let rootType = _.get(pageEditController.route, 'rootType');
            if (!rootType) {
                rootType = pageEditController.page.filterType || pageEditController.page.rootType;
            }
            return EntityService.getEntityType(rootType);
        }

        function getTabs() {
            if (angular.isUndefined(pageEditController.page.id)) {
                return $q.resolve([]);
            }

            return Tab.search({
                pageId: pageEditController.page.id
            }).$promise.then((tabs) => {
                return _.forEach(tabs, (tab) => {
                    tab.displayName = enumFilter(tab.rootType);
                });
            });
        }

        function setFields() {
            return getFields().then((fields) => {
                pageEditController.fields = fields;

                updateFields();
                setItems();
                setChildren();
            });
        }

        function getFields() {
            const type = getType();
            if (angular.isDefined(type)) {
                return CustomField.query({
                    type: type.id
                }).$promise;
            }

            const rootType = getRootType();
            if (!rootType) {
                return $q.resolve([]);
            }

            return CustomField.query({
                singular: true,
                rootType
            }).$promise;
        }

        function getType() {
            let type;
            const code = Page.getParameter(pageEditController.page, 'code');
            if (code) {
                type = _.find(pageEditController.types, { code });
            }
            return type;
        }

        function updateFields() {
            _.forEach(pageEditController.fields, (field) => {
                const type = _.find(pageEditController.types, { id: field.type });
                updateField(field, _.result(type, 'context'));
            });
        }

        function updateField(field, type) {
            const entityType = _.get(type, 'entityType');

            field.label = getFieldLabel(field, entityType);
            field.supported = true;
            return field;
        }

        function setItems() {
            const constants = _(pageEditController.constants)
                .filter((type) => type !== 'FIELD')
                .map((type) => buildItem({ type }))
                .value();

            pageEditController.items = _(pageEditController.fields)
                .filter({ supported: true })
                .map((field) => buildItem({ field }))
                .concat(constants)
                .orderBy('label')
                .value();

            setSelectedItems();
            setUnusedItems();
        }

        function buildItem(args) {
            const item = {
                type: args.type
            };

            if (angular.isDefined(args.field)) {
                const fieldId = args.field.id;
                const field = _.find(pageEditController.fields, { id: fieldId });

                return _.extend(item, {
                    type: 'FIELD',
                    fieldId: fieldId,
                    key: `FIELD-${fieldId}`,
                    label: _.result(field, 'label'),
                    visible: field.visible
                });
            }

            return _.extend(item, {
                key: args.type,
                label: enumFilter(args.type)
            });
        }

        function setSelectedItems() {
            pageEditController.selectedItems = _(pageEditController.page.items)
                .sortBy('index')
                .map(buildItem)
                .value();
        }

        function setUnusedItems() {
            pageEditController.unusedItems = _(pageEditController.items)
                .filter((item) => item.visible !== false)
                .filter((item) => !_.some(pageEditController.selectedItems, { key: item.key }))
                .value();
        }

        function setChildren() {
            pageEditController.children = _(pageEditController.pages).filter((page) => {
                const entityType = EntityService.getEntityType(page.rootType);
                return (!page.rootType || isSupported(entityType)) && page.id !== pageEditController.page.id;
            }).map((page) => {
                return _.extend(page, {
                    label: `${page.name} (${page.route})`
                });
            }).sortBy('label').value();

            setSelectedChildren();
            setUnusedChildren();
        }

        function setSelectedChildren() {
            pageEditController.selectedChildren = _(pageEditController.page.children)
                .sortBy('index')
                .map((item) => _.find(pageEditController.pages, { id: item.childId }))
                .filter(angular.isDefined)
                .value();
        }

        function setUnusedChildren() {
            pageEditController.unusedChildren = _.filter(pageEditController.children, (page) => {
                return !_.some(pageEditController.selectedChildren, { id: page.id });
            });
        }

        function getFieldLabel(field, entityType) {
            let name = i18nFilter(field.labels) || `<${field.name}>`;
            const typeName = $filter('type')(entityType);
            if (typeName) {
                name = `${name} (${typeName})`;
            }
            return name;
        }

        pageEditController.isInvalid = function() {
            return _.get(pageEditController.generalForm, '$valid') === false;
        };

        pageEditController.onParameter = function(parameter) {
            if (!hasValue(parameter.value) && parameter.type !== 'boolean') {
                parameter.value = '';
            }

            if (parameter.name === 'code') {
                pageEditController.page.items = [];
                setFields();
            }
        };

        function hasValue(value) {
            return angular.isDefined(value) && value !== '' && value !== null;
        }

        pageEditController.onItem = function() {
            setUnusedItems();
        };

        pageEditController.onChild = function() {
            setUnusedChildren();
        };

        pageEditController.onShowInTabChanged = function () {
            if (!pageEditController.page.showInTab) {
                delete pageEditController.page.tabGroup;
            }
        };

        pageEditController.save = function (page) {
            const body = buildBody(page);
            Page.store(body).$promise.then((result) => {
                Message.addSuccessLabel('Static.Message.DataSaved');
                pageEditController.page = result;
                $state.go('page-edit',
                    { id: result.id },
                    { reload: false }
                );
            });
        };

        function buildBody(page) {
            let body = angular.copy(page);
            body.tabGroup = _.get(page.tabGroup, 'id');
            body.parameters = _.map(pageEditController.parameters, (parameter) => {
                return {
                    name: parameter.name,
                    value: formatValue(parameter.value)
                };
            });
            body.items = _.map(pageEditController.selectedItems, (item, index) => {
                return {
                    type: item.type,
                    fieldId: item.fieldId,
                    index: index
                };
            });
            body.children = _.map(pageEditController.selectedChildren, (child, index) => {
                return {
                    childId: child.id,
                    index: index
                };
            });
            return body;
        }

        function formatValue(value) {
            if (_.isArray(value)) {
                return _.join(value, SEPARATOR);
            }

            return value;
        }
    }
});
