'use strict';

/**
 * @ngdoc function
 * @name uasApp.config:TeamStates
 * @description Configures all states of a team.
 */
angular.module('uasApp').config(function ($stateProvider) {

    $stateProvider.state('teams', {
        url: '/teams',
        template: '<team-list></team-list>',
        breadcrumb: {
            label: 'Static.Page.Teams',
            root: true
        },
        admin: true
    });

    $stateProvider.state('team-create', {
        url: '/team-create',
        controllerAs: 'teamStateController',
        controller: function () {
            const teamStateController = this;
            teamStateController.team = {
                academicYearId: sessionStorage.academicYear
            };
            teamStateController.operations = ['EDIT'];
        },
        breadcrumb: {
            label: 'Static.Page.Team'
        },
        template: `
            <team-create
                team="teamStateController.team"
                operations="teamStateController.operations">
            </team-create>
        `,
        admin: true
    });

    $stateProvider.state('team', {
        url: '/team/{id:[0-9]{1,8}}',
        resolve: {
            team: function(Team, $stateParams) {
                const instance = this;
                return Team.get({
                    id: $stateParams.id
                }).$promise.then((team) => {
                    instance.self.data = team;
                    return team;
                });
            },
            operations: function (AuthService, $stateParams) {
                return AuthService.operations('team', $stateParams.id);
            }
        },
        controllerAs: 'teamController',
        controller: function(team, operations) {
            this.team = team;
            this.operations = operations;
            this.tabs = [{
                view: 'general',
                route: 'team.general',
                readOnly: true
            }, {
                view: 'members',
                route: 'team.members'
            }, {
                view: 'general',
                route: 'team.relations'
            }, {
                view: 'general',
                route: 'team.admin'
            }];
        },
        template: `
            <entity-tabs 
                view="team" 
                entity="teamController.team"
                tabs="teamController.tabs" 
                operations="teamController.operations"
            />
        `,
        abstract: true,
        entityType: 'team',
        redirectTo: 'team.general',
        breadcrumb: {}
    });

    $stateProvider.state('team.general', {
        url: '/general',
        views: {
            'team': {
                controllerAs: 'teamGeneralStateController',
                controller: function(team, operations) {
                    this.team = team;
                    this.operations = operations;
                },
                template: `
                    <team-general
                        team="teamGeneralStateController.team" 
                        operations="teamGeneralStateController.operations">
                    </team-general>
                `
            }
        },
        breadcrumb: {},
        data: {
            title: 'Static.Tab.Team.General',
            label: 'Static.Help.Team.General'
        }
    });

    $stateProvider.state('team.members', {
        url: '/members',
        views: {
            'team': {
                controllerAs: 'teamMembersStateController',
                controller: function(team, operations) {
                    this.team = team;
                    this.operations = operations;
                },
                template: `
                    <team-members 
                        team="teamMembersStateController.team" 
                        operations="teamMembersStateController.operations">
                    </team-members>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['EDIT'],
            title: 'Static.Page.Team.Members',
            label: 'Static.Page.Team.Members.Help'
        }
    });

    $stateProvider.state('team.relations', {
        url: '/relations',
        views: {
            'team': {
                controllerAs: 'teamRelationsStateController',
                controller: function(team, operations) {
                    this.team = team;
                    this.operations = operations;
                },
                template: `
                    <team-relations 
                        team="teamRelationsStateController.team" 
                        operations="teamRelationsStateController.operations">
                    </team-relations>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['EDIT'],
            title: 'Static.Page.Team.Relations',
            label: 'Static.Page.Team.Relations.Help'
        }
    });

    $stateProvider.state('team.admin', {
        url: '/admin',
        views: {
            'team': {
                controllerAs: 'teamAdminStateController',
                controller: function(team, operations) {
                    this.team = team;
                    this.operations = operations;
                },
                template: `
                    <team-admin
                        team="teamAdminStateController.team" 
                        operations="teamAdminStateController.operations">
                    </team-admin>
                `
            }
        },
        breadcrumb: {},
        data: {
            secured: ['EDIT'],
            title: 'Static.Tab.Team.Admin',
            label: 'Static.Help.Team.Admin'
        }
    });

    $stateProvider.state('team.page', {
        url: '/page/{pageId:[0-9]{1,8}}',
        views: {
            'team': {
                controllerAs: 'teamPageStateController',
                controller: function(team, operations, $stateParams) {
                    this.team = team;
                    this.operations = operations;
                    this.pageId = $stateParams.pageId;
                },
                template: `
                    <page-loader 
                        page-id="teamPageStateController.pageId" 
                        entity="teamPageStateController.team" 
                        operations="teamPageStateController.operations">
                    </page-loader>
                `
            }
        },
        breadcrumb: {}
    });

});
