'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:personEdit
 * @description Shows a form to edit a person.
 */
angular.module('uasApp')
  .component('personEdit', {
    bindings: {
      person: '<',
      fields: '<',
      operations: '<',
      onChange: '&',
      onCancel: '&'
    },
    templateUrl: 'es6/person/person.edit.html',
    controllerAs: 'personEditController',
    controller: function(Message, Person) {
      const personEditController = this;

      personEditController.save = function() {
        return save(personEditController.person);
      };

      function save(person) {
        return Person.store(person).$promise.then((result) => {
          updatePassword(result, person.password);
          personEditController.onChange();
          Message.onSaved();
        });
      }

      function updatePassword(person, password) {
        const passwordFilled = !_.isEmpty(password);
        person.passwordFilled = passwordFilled;

        if (passwordFilled) {
          Person.password({
            id: person.id
          }, { password });
        }
      }

      personEditController.removePassword = function() {
        Person.password({
          id: personEditController.person.id
        }, { password: '' }).$promise.then(() => {
          personEditController.onChange();
          Message.onSaved();
        });
      };

      personEditController.cancel = function() {
        personEditController.onCancel();
      };
    }
});
