'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:PersonCtrl
 * @description
 * # PersonCtrl
 * Person root controller
 */
angular.module('uasApp')
    .controller('PersonCtrl', function ($scope, person, Tabs, Person, operations) {

        $scope.person = person;
        $scope.personId = _.get(person, 'id');

        Person.current().$promise.then((currentPerson) => {
            // Tabs which uses ui-router states to generate content.
            const initialTabs = [{
                title: 'Static.Tab.Person.General',
                view: 'general',
                route: 'person.general',
                label: 'Static.Help.Person.General',
                visible: true
            }, {
                title: 'Static.Tab.Person.Assignments',
                view: 'assignments',
                route: 'person.assignments',
                label: 'Static.Help.Person.Assignments'
            }, {
                title: 'Static.Tab.Person.Roles',
                view: 'roles',
                route: 'person.roles',
                label: 'Static.Help.Person.Roles'
            }, {
                title: 'Static.Tab.Person.Relations',
                view: 'relations',
                route: 'person.relations',
                label: 'Static.Help.Person.Relations'
            }, {
                title: 'Static.Tab.Person.Availability',
                view: 'availability',
                route: 'person.availability',
                label: 'Static.Help.Person.Availability',
                visible: true
            }, {
                title: 'Static.Tab.Person.Preferences',
                view: 'preferences',
                route: 'person.preferences',
                label: 'Static.Help.Person.Preferences',
                visible: true
            }, {
                title: 'Static.Page.LoginAsPermission',
                view: 'loginas',
                route: 'person.loginpermission',
                visible: true
            }, {
                title: 'Static.Page.Admin',
                view: 'admin',
                route: 'person.admin',
                visible: true
            }];

            $scope.operations = operations;

            Tabs.build(initialTabs, operations, 'person', 'person', $scope.person.id).then((tabs) => {
                // Only show loginas and notification tab if the person is the current user
                // Would be better if it is blocked on route level, because you can still manually go to the pages
                // But every part of this app is written this way
                tabs.filter((tab) => tab.view === 'loginas').forEach((tab) => {
                    tab.visible = tab.visible && currentPerson.id === person.id;
                });

                $scope.tabs = tabs;
            });
        });
    });
