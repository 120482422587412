'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:teamCreate
 * @description teamCreate Creates a team.
 */
angular.module('uasApp').component('teamCreate', {
  templateUrl: 'es6/team/team.create.html',
  bindings: {
    team: '<',
    operations: '<'
  },
  controllerAs: 'teamCreateController',
  controller: function (Team, Message, $state) {
    const teamCreateController = this;

    teamCreateController.onChange = function () {
      teamCreateController.form.$setDirty(true);
    };

    teamCreateController.save = function () {
      const body = angular.copy(teamCreateController.team);

      return Team.save(body).$promise.then((result) => {
        Message.addSuccessLabel('Static.Message.DataAdjusted');
        teamCreateController.form.$setPristine();

        if (angular.isUndefined(body.id)) {
          $state.go('team.general', { id: result.id });
        }
        
        return result;
      });
    };
  }
});
