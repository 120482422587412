angular.module('uasApp').component('linkBuilder', {
  bindings: {
    entity: '<',
    operations: '<',
    categoryId: '<?',
    workflowMode: '<',
    isReadOnly: '<?'
  },
  templateUrl: 'es6/link/link.builder.html',
  controllerAs: 'linkBuilderController',
  controller: function ($q, AwardQualification, EntityService, Language, Link, LinkType, SecurityService, Study) {
    const linkBuilderController = this;

    linkBuilderController.$onInit = function () {
      linkBuilderController.operationsToEdit = linkBuilderController.workflowMode === true ? 'EDIT_LINKS_WORKFLOW' : 'EDIT_LINKS';
      linkBuilderController.editable = linkBuilderController.isReadOnly !== true && SecurityService.isAllowed(linkBuilderController.operationsToEdit, linkBuilderController.operations);

      $q.all([
        getLinkTypes(),
        getStudies()
      ]).then(([linkTypes, studies]) => {
        linkBuilderController.linkTypes = linkTypes;
        if (linkBuilderController.linkTypes.length === 1) {
          linkBuilderController.typeId = linkBuilderController.linksTypes[0].id;
        }

        linkBuilderController.studies = studies;
        if (linkBuilderController.studies.length === 1) {
          linkBuilderController.studyId = linkBuilderController.studies[0].id;
        }

        loadLinks().then(() => {
          linkBuilderController.selected = getSelected(linkBuilderController.links);
        });
      });
    };

    function getLinkTypes() {
      return LinkType.query().$promise.then((types) => {
        return _.filter(types, (type) =>
          angular.isUndefined(linkBuilderController.categoryId) || type.categoryId === linkBuilderController.categoryId
        );
      });
    }

    function getStudies() {
      return getStudyParams().then((params) => {
        return Study.query(params).$promise;
      });
    }

    function getStudyParams() {
      let params = {
        academicYearId: sessionStorage.academicYear
      };

      if (EntityService.hasType(linkBuilderController.entity, 'qualification')) {
        return AwardQualification.query({
          qualificationId: linkBuilderController.entity.id
        }).$promise.then((awards) => {
          params.specificationId = _(awards).map('entity').filter({ type: 'specification' }).map('id').value();
          return params;
        });
      } else {
        params.facultyId = _.get(linkBuilderController.entity, 'facultyId');
        return $q.resolve(params);
      }
    }

    function loadLinks() {
      delete linkBuilderController.links;

      if (angular.isUndefined(linkBuilderController.typeId) || angular.isUndefined(linkBuilderController.studyId)) {
        return $q.resolve();
      }

      return Link.usage({
        categoryId: linkBuilderController.categoryId,
        entityType: linkBuilderController.entity.self.type,
        entityId: linkBuilderController.entity.id,
        language: Language.get()
      }).$promise.then((links) => {
        linkBuilderController.links = links;
      });
    }

    function getSelected(links) {
      return _(links)
        .filter((link) => link.direction === 'OUTWARD')
        .filter((link) => link.typeId === linkBuilderController.typeId)
        .map('target')
        .value();
    }

    linkBuilderController.onStudy = function () {
      loadLinks().then(() => {
        linkBuilderController.selected = getSelected(linkBuilderController.links);
      });
    };

    linkBuilderController.onType = function () {
      loadLinks().then(() => {
        linkBuilderController.selected = getSelected(linkBuilderController.links);
      });
    };

    linkBuilderController.onToggle = function (entity, selected) {
      let promise;
      if (selected) {
        promise = createLink(entity);
      } else {
        promise = deleteLink(entity);
      }

      promise.then(() => {
        loadLinks();
      });
    };

    function createLink(target) {
      if (angular.isUndefined(linkBuilderController.typeId)) {
        return $q.resolve();
      }

      const link = {
        entity: EntityService.toReference(linkBuilderController.entity),
        typeId: linkBuilderController.typeId,
        target
      };

      return Link.store({
        content: link,
        comment: { message: '' }
      }).$promise;
    }

    function deleteLink(target) {
      const found = _.find(linkBuilderController.links, (link) => link.target.id === target.id && link.target.type === target.type);
      if (!found) {
        return $q.resolve();
      }

      return Link.remove({
        id: found.id
      }).$promise;
    }
  }
});
