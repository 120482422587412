'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:personFields
 * @description Displays custom fields of a person, with the set or default sequence.
 */

angular.module('uasApp')
  .component('personFields', {
    bindings: {
      person: '<',
      fields: '<',
      operations: '<'
    },
    templateUrl: 'es6/person/person.fields.html',
    controllerAs: 'personFieldsController',
    controller: function(AuthService, CustomField, SecurityService) {
      const personFieldsController = this;

      const EXTENSIONS = [
        { name: 'startDate', maxValue: 'endDate' },
        { name: 'endDate', minValue: 'startDate' }
      ];

      personFieldsController.$onInit = function () {
        setOperations();
        setFields();
        setEvaluation();
      };

      function setOperations() {
        const OPERATIONS_TO_EDIT = 'EDIT_PERSONS';

        personFieldsController.operationsToEdit = AuthService.buildOperationsToEdit(OPERATIONS_TO_EDIT, OPERATIONS_TO_EDIT);
        personFieldsController.isAllowed = SecurityService.isAllowed(OPERATIONS_TO_EDIT, personFieldsController.operations);
      }

      function setFields() {
        const fields = CustomField.keyByName(personFieldsController.fields);
        const sortedFields = _.sortBy(fields, 'sequence');

        personFieldsController.fields_ = CustomField.extend(sortedFields, EXTENSIONS);
      }

      personFieldsController.onValue = function () {
        setEvaluation();
      };

      function setEvaluation() {
        const entity = {
          type: 'person',
          id: _.get(personFieldsController.person, 'id')
        };

        personFieldsController.evaluation = CustomField.getEvaluation(
          entity,
          personFieldsController.person,
          personFieldsController.fields_
        );
      }
    }
  });
