'use strict';

/**
 * @ngdoc function
 * @name uasApp.config:PersonStates
 * @description
 * # PersonStates
 * Configures all states of a person.
 */
angular.module('uasApp').config(function ($stateProvider) {

  $stateProvider.state('persons', {
    url: '/persons?page&size&sort&text&open&role&roleId&active&external&ignore&passwordFilled',
    controllerAs: 'personStateController',
    controller: function ($stateParams, operations) {
      this.parameters = $stateParams;
      this.operations = operations;
    },
    resolve: {
      operations: function (AuthService) {
        return AuthService.operations('academic-year', sessionStorage.academicYear);
      }
    },
    template: `
      <person-list
        parameters="personStateController.parameters"
        operations="personStateController.operations">
      </person-list>
   `,
    params: {
      page: {
        value: '1',
        dynamic: true
      },
      size: {
        value: '',
        dynamic: true
      },
      sort: {
        value: 'fullName,asc',
        dynamic: true
      },
      text: {
        value: '',
        dynamic: true
      },
      open: {
        value: '',
        dynamic: true
      },
      role: {
        value: '',
        dynamic: true
      },
      roleId: {
        value: '',
        dynamic: true
      },
      external: {
        value: '',
        dynamic: true
      },
      ignore: {
        value: '',
        dynamic: true
      },
      passwordFilled: {
        value: '',
        dynamic: true
      }
    },
    breadcrumb: {
      label: 'Static.Page.Persons',
      root: true
    },
    admin: true
  });

  $stateProvider.state('person', {
    url: '/person/{id:[0-9]{1,8}}',
    templateUrl: 'es6/person/person.html',
    controller: 'PersonCtrl',
    abstract: true,
    breadcrumb: {},
    resolve: {
      personId: function ($stateParams) {
        return $stateParams.id;
      },
      person: function (Person, personId) {
        const person = Person.get({
          id: personId
        });

        this.self.data = person;
        return person.$promise;
      },
      operations: function (AuthService, personId) {
        return AuthService.operations('person', personId);
      }
    }
  });

  $stateProvider.state('person.general', {
    url: '/general',
    views: {
      'person': {
        template: '<person-general person="person" operations="operations"></person-general>'
      }
    },
    breadcrumb: {}
  });

  $stateProvider.state('person.assignments', {
    url: '/assignments',
    views: {
      'person': {
        template: '<person-assignments person="person" operations="operations"></person-assignments>'
      }
    },
    data: {
        secured: ['VIEW_ASSIGNMENTS']
    }
  });

  $stateProvider.state('person.roles', {
    url: '/roles',
    views: {
      'person': {
        template: '<person-roles person="person" operations="operations"></person-roles>'
      }
    }
  });

  $stateProvider.state('person.relations', {
    url: '/relations',
    views: {
      'person': {
        template: '<person-relations person="person" operations="operations"></person-relations>'
      }
    }
  });

  $stateProvider.state('person.availability', {
    url: '/availability',
    views: {
      'person': {
        template: `
          <person-availability
            person="person"
            operations="operations">
          </person-availability>
        `
      }
    },
    breadcrumb: {},
    data: {
      secured: ['VIEW_AVAILABILITY']
    }
  });

  $stateProvider.state('person.preferences', {
    url: '/preferences',
    views: {
      'person': {
        template: `
          <schedule-preference
            person-id="person.id"
            is-read-only="true"
            operations="operations">
          </schedule-preference>
        `
      }
    },
    breadcrumb: {}
  });

  $stateProvider.state('person.loginpermission', {
    url: '/login-permission',
    views: {
      'person': {
        template: '<person-login-permissions></person-login-permissions>'
      }
    }
  });

  $stateProvider.state('person.admin', {
    url: '/admin',
    views: {
      'person': {
        template: '<person-admin person-id="personId" operations="operations"></person-admin>'
      }
    },
    breadcrumb: {}
  });

  $stateProvider.state('person.page', {
    url: '/page/{pageId:[0-9]{1,8}}',
    views: {
      'person': {
        controller: function($scope, $stateParams) {
            $scope.pageId = $stateParams.pageId;
        },
        template: '<page-loader page-id="pageId" entity="person" operations="operations"></page-loader>'
      }
    },
    breadcrumb: {}
  });
});
