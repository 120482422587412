'use strict';

angular.module('uasApp').component('rowsPage', {
    templateUrl: 'es6/page/rows.page.html',
    bindings: {
        page: '<',
        entity: '<',
        operations: '<',
        process: '<',
        workflow: '<',
        isReadOnly: '<?'
    },
    controllerAs: 'rowsPageController',
    controller: function (Page) {
        const rowsPageController = this;

        rowsPageController.$onInit = function () {
            rowsPageController.readOnly = rowsPageController.isReadOnly || Page.getParameterAsBoolean(rowsPageController.page, 'readOnly', false);

            rowsPageController.collapsible = Page.getParameterAsBoolean(rowsPageController.page, 'collapsible', false);
            rowsPageController.collapsed = Page.getParameterAsBoolean(rowsPageController.page, 'collapsed', false);
            rowsPageController.displayTitle = Page.getParameterAsBoolean(rowsPageController.page, 'hideTitle', true);
            rowsPageController.displayHeaders = Page.getParameterAsBoolean(rowsPageController.page, 'displayHeader', false);
            rowsPageController.displayPrint = Page.getParameterAsBoolean(rowsPageController.page, 'displayPrint', false);

            rowsPageController.loading = true;
            Page.children({
                id: rowsPageController.page.id
            }).$promise.then((children) => {
                rowsPageController.children = children;
            }).finally(() => {
                rowsPageController.loading = false;
            });
        };
    }
});
