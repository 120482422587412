'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:teamList
 * @description teamList Manages Team entities.
 */
angular.module('uasApp').component('teamList', {
  templateUrl: 'es6/team/team.list.html',
  controllerAs: 'teamListController',
  controller: function (Pageable, Team, Message) {
    const teamListController = this;

    teamListController.$onInit = function () {
      teamListController.active = 'true';
      teamListController.pageable = Pageable.of({
        order: 'code'
      });

      teamListController.search();
    };

    teamListController.search = function () {
      const parameters = getParameters();

      teamListController.loading = true;
      return Team.page(parameters).$promise.then((teams) => {
        teamListController.teams = teams;
      }).finally(() => {
        teamListController.loading = false;
      });
    };

    function getParameters() {
      return teamListController.pageable.build({
        text: teamListController.searchText || '',
        active: teamListController.active
      });
    }

    teamListController.removeTeam = function (team) {
      Team.remove({
        id: team.id
      }).$promise.then(function () {
        Message.addSuccessLabel('Static.Message.DataRemoved');
        teamListController.search();
      });
    };
  }
});
