'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:teamAdmin
 * @description teamAdmin Admin the team details.
 */
angular.module('uasApp').component('teamAdmin', {
  templateUrl: 'es6/team/team.admin.html',
  bindings: {
    team: '<',
    operations: '<'
  },
  controllerAs: 'teamAdminController',
  controller: function ($q, Person, Team, Message) {
    const teamAdminController = this;

    teamAdminController.$onInit = function () {
      teamAdminController.team_ = angular.copy(teamAdminController.team);
      loadData();
    };

    function loadData() {
      const promises = _.map(teamAdminController.team_.personIds, (id) =>
        Person.get({ id }).$promise
      );

      $q.all(promises).then((persons) => {
        teamAdminController.persons = persons;
      });
    }

    teamAdminController.onChange = function () {
      teamAdminController.form.$setDirty(true);
    };

    teamAdminController.save = function () {
      const body = angular.copy(teamAdminController.team_);

      return Team.save(body).$promise.then((result) => {
        Message.onSaved();
        teamAdminController.form.$setPristine();
        return result;
      });
    };
  }
});
