'use strict';

/**
 * @ngdoc factory
 * @name uasApp.factory:PersonModal
 * @description Opens a modal to edit a person.
 */
angular.module('uasApp').factory('PersonModal', function(CustomField, Person, $q, $uibModal) {
  function edit({ person, operations, onChange, hidePasswordField = true }) {
    $uibModal.open({
      template: `
        <person-edit
          person="modalController.person"
          fields="modalController.fields"
          operations="modalController.operations"
          on-change="modalController.onChange()"
          on-cancel="modalController.close()">
        </person-edit>
      `,
      resolve: {
        fields: function() {
          return CustomField.query({
              rootType: 'person',
              entityType: 'person'
          }).$promise;
        },
        model: function() {
          if (angular.isUndefined(person.id)) {
            return $q.resolve(person);
          }

          return Person.secure({
              id: person.id
          }).$promise;
        }
      },
      controllerAs: 'modalController',
      controller: function($uibModalInstance, fields, model) {
        const modalController = this;

        modalController.person = model;
        modalController.fields = getFields(hidePasswordField, fields);
        modalController.operations = operations;

        modalController.onChange = function() {
          modalController.close();

          if (_.isFunction(onChange)) {
            onChange();
          }
        };

        modalController.close = function() {
          $uibModalInstance.dismiss();
        };
      }
    });
  }

  // The password field should never be visible in the default report, only (conditionally) on admin
  function getFields(hidePasswordField, fields) {
    if (hidePasswordField) {
      const EXTENSIONS = [
        { name: 'password', visible: false }
      ];

      return CustomField.extend(fields, EXTENSIONS);
    }

    return fields;
  }

  return { edit };

});
