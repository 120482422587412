'use strict';

angular.module('uasApp').component('creditsLabel', {
    bindings: {
        selected: '<?',
        credits: '<',
        isRequired: '<?',
        isValid: '<?'
    },
    templateUrl: 'es6/app/credits/credits.label.html',
    controllerAs: 'creditsLabelController',
    controller: function () {
        const creditsLabelController = this;

        creditsLabelController.$onChanges = function() {
            creditsLabelController.valid = creditsLabelController.isValid === true;
            creditsLabelController.label = getLabel(creditsLabelController.credits);
        };

        function getLabel(credits) {
            if (angular.isUndefined(credits)) {
                return '';
            }
            
            const minimum = credits.minimum;
            const maximum = credits.maximum;
            const optimum = credits.optimum;

            let label = '';
            if (minimum && maximum) {
                label = minimum === maximum ? `${minimum}` : `${minimum}-${maximum}`;
            } else if (isPositive(minimum)) {
                label = `${minimum}+`;
            } else if (isPositive(maximum)) {
                label = `0-${maximum}`;
            }

            if (isPositive(optimum) && label !== `${optimum}`) {
                label = label ? `${label} (${optimum})` : `${optimum}`;
            }

            return label;
        }

        function isPositive(value) {
            return _.isNumber(value) && value > 0;
        }
    }
});