'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:personAdmin
 * @description Person admin controller
 */

angular.module('uasApp')
  .component('personAdmin', {
    bindings: {
      personId: '<',
      operations: '<?'
    },
    templateUrl: 'es6/person/person.admin.html',
    controllerAs: 'personAdminController',
    controller: function($q, CustomField, Person) {
      const personAdminController = this;

      personAdminController.$onInit = function() {
        loadData();
      };

      function loadData() {
        personAdminController.loading = true;

        $q.all([
          CustomField.query({
            rootType: 'person'
          }).$promise,
          loadPerson()
        ]).then(([fields, person]) => {
          personAdminController.fields = fields;
          personAdminController.person = person;
        }).finally(() => {
          personAdminController.loading = false;
        });
      }

      function loadPerson() {
        return Person.secure({
          id: personAdminController.personId
        }).$promise;
      }

      personAdminController.save = function() {
        Person.store(personAdminController.person).$promise.then(() => {
          loadPerson().then((person) => {
            personAdminController.person = person;
          });
        });
      };
    }
});
